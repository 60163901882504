module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","my","fr"],"redirect":false,"defaultLanguage":"en","siteUrl":"https://safetag-dev.jplusplus.pt/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-flexsearch/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"type":"MarkdownRemark","fields":[{"name":"title","resolver":"frontmatter.title","indexed":true,"store":true},{"name":"description","resolver":"frontmatter.description","indexed":true,"store":false},{"name":"summary","resolver":"frontmatter.summary","indexed":true,"store":false},{"name":"materials_needed","resolver":"frontmatter.materials_needed","indexed":true,"store":false},{"name":"overview","resolver":"frontmatter.materials_needed","indexed":true,"store":false},{"name":"walk_through","resolver":"frontmatter.walk_through","indexed":true,"store":false},{"name":"guiding_questions","resolver":"frontmatter.guiding_questions","indexed":true,"store":false},{"name":"outputs","resolver":"frontmatter.outputs","indexed":true,"store":false},{"name":"operational_security","resolver":"frontmatter.operational_security","indexed":true,"store":false},{"name":"purpose","resolver":"frontmatter.purpose","indexed":true,"store":false},{"name":"preparation","resolver":"frontmatter.preparation","indexed":true,"store":false},{"name":"url","resolver":"fields.slug","indexed":false,"store":true},{"name":"type","resolver":"fields.content_type","indexed":false,"store":true},{"name":"lang","resolver":"fields.langKey","indexed":false,"store":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://stats.openinternetproject.org","siteUrl":"https://safetag-dev.jplusplus.pt"},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
